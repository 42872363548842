var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            class: [
              _vm.scenarioViewGroup === "L"
                ? "col-12"
                : "col-xs-12 col-sm-6 col-md-4 col-lg-4",
              "scenario-list",
            ],
          },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "LBL0001202",
                  columns: _vm.gridColumns,
                  data: _vm.target.scenarios,
                  gridHeight: _vm.setheight,
                  merge: _vm.scenarioViewGroup === "L" ? _vm.grid.merge : [],
                  filtering: false,
                  columnSetting: false,
                  usePaging: false,
                  editable: _vm.editable && !_vm.disabled,
                  customTrClass: _vm.setTrClass,
                  selection: "multiple",
                  rowKey: "ramChecklistAssessScenarioId",
                },
                scopedSlots: _vm._u([
                  {
                    key: "suffixTitle",
                    fn: function () {
                      return [
                        _c(
                          "q-btn-group",
                          {
                            staticClass: "scenario-view-group",
                            attrs: { outline: "" },
                          },
                          [
                            _c(
                              "q-btn",
                              {
                                attrs: {
                                  outline: _vm.scenarioViewGroup !== "L",
                                  color:
                                    _vm.scenarioViewGroup === "L"
                                      ? "indigo"
                                      : "grey",
                                  size: "11px",
                                  label: "",
                                  icon: "view_list",
                                },
                                on: { click: _vm.listView },
                              },
                              [
                                _c("q-tooltip", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$label("LBL0001167")) + " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "q-btn",
                              {
                                attrs: {
                                  outline: _vm.scenarioViewGroup !== "C",
                                  color:
                                    _vm.scenarioViewGroup === "C"
                                      ? "indigo"
                                      : "grey",
                                  size: "11px",
                                  label: "",
                                  icon: "space_dashboard",
                                },
                                on: { click: _vm.cardView },
                              },
                              [
                                _c("q-tooltip", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$label("LBL0001168")) + " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "customArea",
                    fn: function ({ props, col }) {
                      return [
                        col.name === "customCol"
                          ? [
                              _c(_vm.imprComponent, {
                                tag: "component",
                                attrs: {
                                  col: col,
                                  props: props,
                                  inputEditable:
                                    _vm.editable &&
                                    !_vm.disabled &&
                                    Boolean(
                                      props.row.ramChecklistAssessScenarioId
                                    ),
                                  requestContentsCols: _vm.requestContentsCols,
                                  tableKey: "ramChecklistAssessScenarioId",
                                  ibmTaskTypeCd: "ITT0000010",
                                  ibmTaskUnderTypeCd: "ITTU000010",
                                },
                                on: { imprChange: _vm.imprChange },
                              }),
                            ]
                          : col.name === "checkItemName"
                          ? [
                              props.row.checkItemId &&
                              props.row.ramCheckItemClassCd !== "RCIC000015"
                                ? [
                                    _c("q-btn", {
                                      staticClass: "tableinnerBtn",
                                      attrs: {
                                        flat: "",
                                        align: "left",
                                        color: "blue-6",
                                        label: props.row[col.name],
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.openItem(props.row)
                                        },
                                      },
                                    }),
                                  ]
                                : [
                                    _vm._v(
                                      " " + _vm._s(props.row[col.name]) + " "
                                    ),
                                  ],
                            ]
                          : col.name === "item"
                          ? [
                              _c(
                                "q-item",
                                { staticClass: "card-scenario-list" },
                                [
                                  _c(
                                    "q-item-section",
                                    [
                                      _c(
                                        "q-item-label",
                                        { staticClass: "scenario-card-title" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              `${props.row["checklistQuestion"]}`
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-section",
                                    {
                                      staticStyle: { "padding-right": "0px" },
                                      attrs: { side: "" },
                                    },
                                    [
                                      _c("q-btn", {
                                        attrs: {
                                          unelevated: "",
                                          dense: "",
                                          color: "primary",
                                          label: "▶",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getDetail(
                                              props.row
                                                .ramChecklistAssessScenarioId
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : col.name === "picture"
                          ? [
                              _c("q-btn", {
                                attrs: {
                                  unelevated: "",
                                  round: "",
                                  dense: "",
                                  size: "7px",
                                  color: "primary",
                                  icon: "wallpaper",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.openImprPicture(props)
                                  },
                                },
                              }),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isCompared,
                              expression: "isCompared",
                            },
                          ],
                          attrs: {
                            label: "LBL0001166",
                            icon: "compare_arrows",
                          },
                          on: { btnClicked: _vm.compareScenario },
                        }),
                        _vm.editable && !_vm.disabled
                          ? _c("c-btn", {
                              attrs: { label: "LBLREMOVE", icon: "remove" },
                              on: { btnClicked: _vm.removeScenario },
                            })
                          : _vm._e(),
                        _vm.editable && !_vm.disabled
                          ? _c("c-btn", {
                              attrs: {
                                isSubmit: _vm.isSave,
                                url: _vm.saveUrl,
                                param: _vm.target.scenarios,
                                mappingType: "PUT",
                                label: "LBLSAVE",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveScenario,
                                btnCallback: _vm.saveScenarioCallback,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _vm.scenarioViewGroup === "C"
          ? _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-6 col-md-8 col-lg-8 scenario-card",
              },
              [
                _c("q-form", { ref: "editForm" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-5" },
                      [
                        _c(
                          "c-card",
                          {
                            staticClass: "cardClassDetailForm no-margin",
                            attrs: { title: "LBL0001169" },
                          },
                          [
                            _c(
                              "template",
                              { slot: "card-button" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _c("c-btn", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.editable && !_vm.disabled,
                                          expression: "editable && !disabled",
                                        },
                                      ],
                                      attrs: {
                                        isSubmit: _vm.isSaveUnit,
                                        url: _vm.saveUnitUrl,
                                        param: _vm.scenario,
                                        mappingType: "PUT",
                                        label: "LBLSAVE",
                                        icon: "save",
                                      },
                                      on: {
                                        beforeAction: _vm.saveScenarioUnit,
                                        btnCallback:
                                          _vm.saveScenarioUnitCallback,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("template", { slot: "card-detail" }, [
                              _c(
                                "div",
                                { staticClass: "col-5" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      disabled: true,
                                      editable: _vm.editable,
                                      label: "LBLCLASSIFICATION",
                                      name: "ramCheckItemClassName",
                                    },
                                    model: {
                                      value: _vm.scenario.ramCheckItemClassName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "ramCheckItemClassName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "scenario.ramCheckItemClassName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-7" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      disabled: true,
                                      editable: _vm.editable,
                                      label: "LBL0001028",
                                      name: "checkItemName",
                                    },
                                    model: {
                                      value: _vm.scenario.checkItemName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "checkItemName",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.checkItemName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c("c-textarea", {
                                    attrs: {
                                      disabled: true,
                                      editable: _vm.editable,
                                      label: "LBL0001092",
                                      name: "checklistQuestion",
                                    },
                                    model: {
                                      value: _vm.scenario.checklistQuestion,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "checklistQuestion",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.checklistQuestion",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      disabled:
                                        _vm.disabled || !_vm.selectScenario,
                                      editable: _vm.editable,
                                      label: "LBL0001021",
                                      name: "relationLaw",
                                    },
                                    model: {
                                      value: _vm.scenario.relationLaw,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "relationLaw",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.relationLaw",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c("c-checkbox", {
                                    attrs: {
                                      disabled:
                                        _vm.disabled || !_vm.selectScenario,
                                      editable: _vm.editable,
                                      isFlag: true,
                                      label: "LBL0000369",
                                      name: "riskbookFlag",
                                    },
                                    model: {
                                      value: _vm.scenario.riskbookFlag,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "riskbookFlag",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.riskbookFlag",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c("c-datepicker", {
                                    attrs: {
                                      disabled:
                                        _vm.disabled || !_vm.selectScenario,
                                      editable: _vm.editable,
                                      label: "LBL0001013",
                                      name: "assessDate",
                                    },
                                    model: {
                                      value: _vm.scenario.assessDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "assessDate",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.assessDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c("c-field", {
                                    attrs: {
                                      disabled:
                                        _vm.disabled || !_vm.selectScenario,
                                      editable: _vm.editable,
                                      data: _vm.scenario,
                                      label: "LBL0001014",
                                      name: "assessUserId",
                                    },
                                    on: {
                                      username: (val) => {
                                        _vm.scenario.assessUserName = val
                                      },
                                    },
                                    model: {
                                      value: _vm.scenario.assessUserId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "assessUserId",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.assessUserId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-7" },
                      [
                        _c(
                          "c-card",
                          {
                            staticClass: "cardClassDetailForm no-margin",
                            attrs: { title: "LBL0001172" },
                          },
                          [
                            _c("template", { slot: "card-detail" }, [
                              _c(
                                "div",
                                { staticClass: "col-4" },
                                [
                                  _c("c-upload-picture", {
                                    attrs: {
                                      height: "170px",
                                      attachInfo: _vm.attachBeforeInfo,
                                      editable:
                                        _vm.editable &&
                                        !_vm.disabled &&
                                        _vm.selectScenario,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "col-8" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12" },
                                    [
                                      _c("c-textarea", {
                                        attrs: {
                                          disabled:
                                            _vm.disabled || !_vm.selectScenario,
                                          editable: _vm.editable,
                                          rows: 3,
                                          label: "LBL0001025",
                                          name: "currentSafetyMeasures",
                                        },
                                        model: {
                                          value:
                                            _vm.scenario.currentSafetyMeasures,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.scenario,
                                              "currentSafetyMeasures",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scenario.currentSafetyMeasures",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-12" },
                                    [
                                      _c("c-select", {
                                        attrs: {
                                          disabled:
                                            _vm.disabled || !_vm.selectScenario,
                                          editable: _vm.editable,
                                          codeGroupCd: "RAM_INSPECT_RESULT_CD",
                                          type: "edit",
                                          itemText: "codeName",
                                          itemValue: "code",
                                          label: "LBL0001007",
                                          name: "ramInspectResultCd",
                                        },
                                        model: {
                                          value:
                                            _vm.scenario.ramInspectResultCd,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.scenario,
                                              "ramInspectResultCd",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scenario.ramInspectResultCd",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c(
                                    "c-table",
                                    {
                                      attrs: {
                                        title: "LBL0001174",
                                        editable:
                                          _vm.editable &&
                                          !_vm.disabled &&
                                          _vm.selectScenario,
                                        columns: _vm.gridImpr.columns,
                                        data: _vm.scenario.imprs,
                                        gridHeight: _vm.gridImpr.height,
                                      },
                                      on: {
                                        linkClick: (row) =>
                                          _vm.linkClick(
                                            {
                                              sopImprovementId:
                                                row.sopImprovementId,
                                            },
                                            row
                                          ),
                                      },
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "table-button" },
                                        [
                                          _c(
                                            "q-btn-group",
                                            { attrs: { outline: "" } },
                                            [
                                              _vm.editable &&
                                              !_vm.disabled &&
                                              _vm.selectScenario
                                                ? _c("c-btn", {
                                                    attrs: {
                                                      label: "LBLIMPRREQUEST",
                                                      icon: "add",
                                                    },
                                                    on: {
                                                      btnClicked:
                                                        _vm.openImprRequest,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-4" },
                                [
                                  _c("c-upload-picture", {
                                    attrs: {
                                      isMultiTask: "",
                                      attachInfo: _vm.attachAfterInfo,
                                      editable:
                                        _vm.editable &&
                                        !_vm.disabled &&
                                        _vm.selectScenario,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-8" },
                                [
                                  _c("c-textarea", {
                                    attrs: {
                                      disabled:
                                        _vm.disabled || !_vm.selectScenario,
                                      editable: _vm.editable,
                                      rows: 3,
                                      label: "LBL0001122",
                                      name: "improvementMeasures",
                                    },
                                    model: {
                                      value: _vm.scenario.improvementMeasures,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "improvementMeasures",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "scenario.improvementMeasures",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("c-win-pop", {
        ref: "winPopup",
        on: {
          onClose: (val) => _vm.evtCloseWinPopup(val),
          onRecvEvtFromWinPop: (val) => _vm.onRecvWinPop(val),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }