<template>
  <div>
    <div class="row">
      <div :class="[scenarioViewGroup==='L' ? 'col-12' : 'col-xs-12 col-sm-6 col-md-4 col-lg-4', 'scenario-list']"> 
        <!-- 체크리스트 목록 -->
        <c-table
          ref="table"
          title="LBL0001202"
          :columns="gridColumns"
          :data="target.scenarios"
          :gridHeight="setheight"
          :merge="scenarioViewGroup==='L' ? grid.merge : []"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable&&!disabled"
          :customTrClass="setTrClass"
          selection="multiple"
          rowKey="ramChecklistAssessScenarioId"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!-- 이전평가결과조회 -->
              <c-btn
                v-show="isCompared"
                label="LBL0001166" 
                icon="compare_arrows" 
                @btnClicked="compareScenario" />
              <!-- 삭제 -->
              <c-btn v-if="editable&&!disabled" label="LBLREMOVE" icon="remove" @btnClicked="removeScenario" />
              <!-- 저장 -->
              <c-btn 
                v-if="editable&&!disabled" 
                :isSubmit="isSave"
                :url="saveUrl"
                :param="target.scenarios"
                mappingType="PUT"
                label="LBLSAVE" 
                icon="save"
                @beforeAction="saveScenario"
                @btnCallback="saveScenarioCallback" />
            </q-btn-group>
          </template>
          <template v-slot:suffixTitle>
            <q-btn-group outline class="scenario-view-group">
              <q-btn 
                :outline="scenarioViewGroup!=='L'"
                :color="scenarioViewGroup==='L' ? 'indigo' : 'grey'" 
                size="11px" label="" icon="view_list"
                @click="listView"
              >
                <q-tooltip>
                  <!-- 목록형 -->
                  {{$label('LBL0001167')}}
                </q-tooltip>
              </q-btn>
              <q-btn 
                :outline="scenarioViewGroup!=='C'"
                :color="scenarioViewGroup==='C' ? 'indigo' : 'grey'" 
                size="11px" label="" icon="space_dashboard"
                @click="cardView"
              >
                <q-tooltip>
                  <!-- 카드형 -->
                  {{$label('LBL0001168')}}
                </q-tooltip>
              </q-btn>
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='customCol'">
              <component
                :is="imprComponent"
                :col="col"
                :props="props"
                :inputEditable="editable && !disabled && Boolean(props.row.ramChecklistAssessScenarioId)"
                :requestContentsCols="requestContentsCols"
                tableKey="ramChecklistAssessScenarioId"
                ibmTaskTypeCd="ITT0000010"
                ibmTaskUnderTypeCd="ITTU000010"
                @imprChange="imprChange"
              />
            </template>
            <template v-else-if="col.name==='checkItemName'">
              <template v-if="props.row.checkItemId && props.row.ramCheckItemClassCd !== 'RCIC000015'">
                <!-- 작업, 기계·기구, 유해인자 & link 걸린 대상 -->
                <q-btn
                  flat
                  class="tableinnerBtn"
                  align="left"
                  color="blue-6"
                  :label="props.row[col.name]"
                  @click.stop="openItem(props.row)" />
              </template>
              <template v-else>
                <!-- 기타 & link 걸리지 않은 대상 -->
                {{props.row[col.name]}}
              </template>
            </template>
            <template v-else-if="col.name==='item'">
              <q-item class="card-scenario-list">
                <q-item-section>
                  <q-item-label class="scenario-card-title">{{`${props.row['checklistQuestion']}`}}</q-item-label>
                </q-item-section>
                <q-item-section side style="padding-right: 0px;">
                  <q-btn 
                    unelevated  dense
                    color="primary"
                    label="▶"
                    @click="getDetail(props.row.ramChecklistAssessScenarioId)" />
                </q-item-section>
              </q-item>
            </template>
            <template v-else-if="col.name==='picture'">
              <q-btn 
                unelevated round dense
                size="7px"
                color="primary" 
                icon="wallpaper"
                @click.stop="openImprPicture(props)" />
            </template>
          </template>
        </c-table>
      </div>
      <div v-if="scenarioViewGroup==='C'" class="col-xs-12 col-sm-6 col-md-8 col-lg-8 scenario-card">
        <q-form ref="editForm">
          <div class="row">
            <div class="col-5">
              <!-- 상세정보 -->
              <c-card title="LBL0001169" class="cardClassDetailForm no-margin">
                <template slot="card-button">
                  <q-btn-group outline >
                    <c-btn 
                      v-show="editable && !disabled" 
                      :isSubmit="isSaveUnit"
                      :url="saveUnitUrl"
                      :param="scenario"
                      mappingType="PUT"
                      label="LBLSAVE" 
                      icon="save"
                      @beforeAction="saveScenarioUnit"
                      @btnCallback="saveScenarioUnitCallback" />
                  </q-btn-group>
                </template>
                <template slot="card-detail">
                  <div class="col-5">
                    <!-- 분류 -->
                    <c-text
                      :disabled="true"
                      :editable="editable"
                      label="LBLCLASSIFICATION"
                      name="ramCheckItemClassName"
                      v-model="scenario.ramCheckItemClassName">
                    </c-text>
                  </div>
                  <div class="col-7">
                    <!-- 평가대상 -->
                    <c-text
                      :disabled="true"
                      :editable="editable"
                      label="LBL0001028"
                      name="checkItemName"
                      v-model="scenario.checkItemName">
                    </c-text>
                  </div>
                  <div class="col-12">
                    <!-- 체크리스트 문항 -->
                    <c-textarea
                      :disabled="true"
                      :editable="editable"
                      label="LBL0001092"
                      name="checklistQuestion"
                      v-model="scenario.checklistQuestion">
                    </c-textarea>
                  </div>
                  <div class="col-6">
                    <!-- 관련법규 -->
                    <c-text
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="LBL0001021"
                      name="relationLaw"
                      v-model="scenario.relationLaw">
                    </c-text>
                  </div>
                  <div class="col-6">
                    <!-- 위험등록부 -->
                    <c-checkbox
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :isFlag="true"
                      label="LBL0000369"
                      name="riskbookFlag"
                      v-model="scenario.riskbookFlag"
                    />
                  </div>
                  <div class="col-6">
                    <!-- 평가일 -->
                    <c-datepicker
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="LBL0001013"
                      name="assessDate"
                      v-model="scenario.assessDate"
                    />
                  </div>
                  <div class="col-6">
                    <!-- 평가자 -->
                    <c-field
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :data="scenario"
                      label="LBL0001014"
                      name="assessUserId"
                      v-model="scenario.assessUserId"
                      @username="(val) => { scenario.assessUserName = val }" />
                  </div>
                </template>
              </c-card>
            </div>
            <div class="col-7">
              <!-- 개선정보 -->
              <c-card title="LBL0001172" class="cardClassDetailForm no-margin">
                <template slot="card-detail">
                  <div class="col-4">
                    <!-- 사진 -->
                    <c-upload-picture
                      height="170px"
                      :attachInfo="attachBeforeInfo"
                      :editable="editable&&!disabled&&selectScenario">
                    </c-upload-picture>
                  </div>
                  <div class="col-8">
                    <div class="row">
                      <div class="col-12">
                        <!-- 현재안전조치 -->
                        <c-textarea
                          :disabled="disabled||!selectScenario"
                          :editable="editable"
                          :rows="3"
                          label="LBL0001025"
                          name="currentSafetyMeasures"
                          v-model="scenario.currentSafetyMeasures">
                        </c-textarea>
                      </div>
                      <div class="col-12">
                        <c-select
                          :disabled="disabled||!selectScenario"
                          :editable="editable"
                          codeGroupCd="RAM_INSPECT_RESULT_CD"
                          type="edit"
                          itemText="codeName"
                          itemValue="code"
                          label="LBL0001007"
                          name="ramInspectResultCd"
                          v-model="scenario.ramInspectResultCd"
                        ></c-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <!-- 개선 목록 -->
                    <c-table
                      title="LBL0001174"
                      :editable="editable&&!disabled&&selectScenario"
                      :columns="gridImpr.columns"
                      :data="scenario.imprs"
                      :gridHeight="gridImpr.height"
                      @linkClick="(row) => linkClick({ sopImprovementId: row.sopImprovementId }, row)"
                    >
                      <template slot="table-button">
                        <q-btn-group outline>
                          <!-- 개선요청 -->
                          <c-btn 
                            v-if="editable&&!disabled&&selectScenario" 
                            label="LBLIMPRREQUEST" 
                            icon="add" 
                            @btnClicked="openImprRequest" />
                        </q-btn-group>
                      </template>
                    </c-table>
                  </div>
                  <div class="col-4">
                    <!-- 사진 -->
                    <c-upload-picture
                      isMultiTask
                      :attachInfo="attachAfterInfo"
                      :editable="editable&&!disabled&&selectScenario">
                    </c-upload-picture>
                  </div>
                  <div class="col-8">
                    <!-- 추가 리스크관리 계획 -->
                    <c-textarea
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :rows="3"
                      label="LBL0001122"
                      name="improvementMeasures"
                      v-model="scenario.improvementMeasures">
                    </c-textarea>
                  </div>
                </template>
              </c-card>
            </div>
          </div>
        </q-form>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <c-win-pop 
      ref="winPopup"  
      @onClose="val=>evtCloseWinPopup(val)"  
      @onRecvEvtFromWinPop="val=>onRecvWinPop(val)" />
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'checklist-action-detail',

  props: {
    target: {
      type: Object,
      default: () => ({
        ramAssessChecklistTargetId: '',  // 공종/유해인자/설비 일련번호
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramCheckItemClassCd: '',  // 분류코드
        ramCheckItemClassName: '',  // 분류코드
        checkItemName: '',  // 분류명
        scenarios: [],
        reCheckedCnt: 0,
      }),
    },
    process: {
      type: Object,
      default: () => ({
        ramAssessChecklistTargetId: '',  // 공종/유해인자/설비 일련번호
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramCheckItemClassCd: '',  // 분류코드
        ramCheckItemClassName: '',  // 분류코드
        checkItemName: '',  // 분류명
        scenarios: [],
        reCheckedCnt: 0,
      }),
    },
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        ramTechniqueCd: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
    updateBtnData: {
      type: Object,
      default: () => ({
        title: '평가',
        flag: false,
        research: '',
      }),
    },
    planUpdateBtnData: {
      title: 'LBLPLAN',
      flag: false,
      research: '',
      planResearch: '',
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'checkClassGroup' },
          { index: 1, colName: 'checkItemGroup' },
        ],
        columns: [],
        data: [],
        height: '600px'
      },
      gridImpr: {
        columns: [
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            style: 'width:85px',
            sortable: true
          },
          {
            name: 'improveRequestDeptName',
            field: 'improveRequestDeptName',
            // 요청부서
            label: 'LBL0000927',
            align: 'center',
            style: 'width:85px',
            sortable: true,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            // 조치부서
            label: 'LBL0001018',
            align: 'center',
            style: 'width:85px',
            sortable: true,
          },
        ],
        height: '250px'
      },
      scenario: {
        ramChecklistAssessScenarioId: '',  // 시나리오 일련번호_시나리오의 한 라인(원일별)
        ramAssessChecklistTargetId: '',  // 공종/유해인자/설비 일련번호
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramCheckItemClassCd: '',  // 분류코드
        checkItemId: '',  // 분류 id
        checkItemName: '',  // 분류명
        checklistQuestion: '',  // 체크리스트 문항
        relationLaw: '',  // 관련법규
        currentSafetyMeasures: '',  // 위험성 감소대책
        improvementMeasures: '',  // 개선대책_개선요청과 별개로 수기 입력
        ramInspectResultCd: null,  // 결정(적정/보완/해당없음)
        assessDate: '',  // 평가일
        assessUserId: '',  // 평가자 ID
        assessUserName: '',  // 평가자명
        remark: '',  // 비고
        compareId: '',  // 비교 대상 일련번호(시나리오 일련번호)
        recheckFlag: '',  // 재검토 여부
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        imprs: [], // 개선목록
      },
      attachBeforeInfo: {
        isSubmit: '',
        taskClassCd: 'CHECKLIST_SCENARIO_BEFORE',
        taskKey: '',
      },
      attachAfterInfo: {
        isSubmit: '',
        task: []
      },
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      activeWinProps: {
        processCd: '',
        ramChecklistAssessScenarioId: '',
      },
      scenarioViewGroup: 'L',
      editable: true,
      isSave: false,
      isSaveUnit: false,
      listUrl: '',
      detailUrl: '',
      listImprUrl: '',
      saveUrl: '',
      saveUnitUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    isCompared() {
      return this.process.reCheckedCnt > 0;
    },
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 180) + 'px' : '500px'
    },
    gridColumns() {
      let cols = [];
      if (this.scenarioViewGroup === 'L') {
        cols = [
          {
            fix: true,
            name: 'ramCheckItemClassName',
            field: 'ramCheckItemClassName',
            // 분류
            label: 'LBLCLASSIFICATION',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            fix: true,
            name: 'checkItemName',
            field: 'checkItemName',
            // 평가대상
            label: 'LBL0001028',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false,
          },
          {
            name: 'checklistQuestion',
            field: 'checklistQuestion',
            // 체크리스트 문항
            label: 'LBL0001092',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            // 관련법규
            label: 'LBL0001021',
            align: 'left',
            style: 'width:120px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            type: 'textarea',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'ramInspectResultCd',
            field: 'ramInspectResultCd',
            // 결과
            label: 'LBL0001007',
            align: 'center',
            style: 'width:90px',
            type: 'select',
            setHeader: true,
            codeGroupCd: 'RAM_INSPECT_RESULT_CD',
            required: true,
          },
          {
            name: 'picture',
            field: 'picture',
            // 개선<br/>전/후<br/>사진
            label: 'LBL0001176',
            align: 'center',
            type: 'custom',
            style: 'width:40px',
            sortable: false,
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 추가 리스크관리 계획
            label: 'LBL0001122',
            align: 'left',
            style: 'width:200px',
            type: 'textarea',
            sortable: false
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            // 평가일
            label: 'LBL0001013',
            align: 'center',
            type: 'date',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            // 평가자
            label: 'LBL0001014',
            align: 'center',
            type: 'user',
            userId: 'assessUserId',
            style: 'width:100px',
            sortable: false,
            setHeader: true,
          },
          {
            name: 'riskbookFlag',
            field: 'riskbookFlag',
            // 위험<br/>등록부
            label: 'LBL0001187',
            align: 'center',
            style: 'width:55px',
            sortable: true,
            type: 'check',
            'true': 'Y',
            'false': 'N',
            disableTarget: 'riskRegisterFlag',
            disableCon: false
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: 'LBL0001178',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: 'LBLIMPRNO_PROGRESS',
                align: 'center',
                style: 'width:150px',
                type: 'custom',
                sortable: false
              },
            ]
          },
        ]
      } else {
        cols = [
          {
            name: 'item',
            field: 'item',
            // 평가내용
            label: 'LBL0001179',
            align: 'left',
            sortable: false,
            type: 'custom'
          },
        ]
      }
      return cols;
    },
    selectScenario() {
      return Boolean(this.scenario.ramChecklistAssessScenarioId)
    },
    requestContentsCols() { 
      return ['ramCheckItemClassName', 'checkItemName'];
    }
  },
  watch: {
    'updateBtnData.research'() {
      this.getList();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('beforeunload', () => {
      this.$refs.winPopup.closeWinPop()
    });
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.$refs.winPopup.closeWinPop()
    window.removeEventListener('beforeunload', () => {
      this.$refs.winPopup.closeWinPop()
    });
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.checklist.scenario.list.url
      this.detailUrl = selectConfig.ram.checklist.scenario.get.url
      this.listImprUrl = selectConfig.ram.checklist.riskReduce.impr.url
      this.saveUrl = transactionConfig.ram.checklist.scenario.save.url
      this.saveUnitUrl = transactionConfig.ram.checklist.scenario.saveUnit.url
      this.deleteUrl = transactionConfig.ram.checklist.scenario.delete.url
      // code setting
      // list setting
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramAssessChecklistTargetId: this.target.ramAssessChecklistTargetId,
      }
      this.$http.request((_result) => {
        this.target.scenarios = _result.data;
      },);
    },
    getDetail(ramChecklistAssessScenarioId) {
      this.$http.url = this.$format(this.detailUrl, ramChecklistAssessScenarioId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.scenario, _result.data);

        this.$set(this.attachBeforeInfo, 'taskKey', this.scenario.ramChecklistAssessScenarioId)
        this.$set(this.attachAfterInfo, 'task', this.$_.map(this.scenario.imprs, impr => {
          return {
            taskClassCd: 'IBM_AFTER',
            taskKey: impr.sopImprovementId,
          }
        }))
      },);
    },
    getImprs() {
      this.$http.url = this.listImprUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramChecklistAssessScenarioId: this.scenario.ramChecklistAssessScenarioId,
      }
      this.$http.request((_result) => {
        this.$set(this.scenario, 'imprs', _result.data)
      },);
    },
    research() {
      this.$emit('research', 'scenario')
    },
    openItem(row) {
      if (row.ramCheckItemClassCd === 'RCIC000001') {
        this.popupOptions.title = 'LBL0001093'; // 안전작업표준 상세
        this.popupOptions.param = {
          mdmSopId: row.checkItemId,
        };
        this.popupOptions.target = () => import(`${'@/pages/mdm/pim/sopDetail.vue'}`);
      } else if (row.ramCheckItemClassCd === 'RCIC000005') {
        this.popupOptions.title = "LBL0000956"; // 유해위험기계기구 상세
        this.popupOptions.param = {
          hhmHazardousMachineryId: row.checkItemId,
        };
        this.popupOptions.target = () => import(`${"@/pages/sop/mim/hazardEquipment/hazardEquipmentClassDetail.vue"}`);
      } else {
        this.popupOptions.title = 'LBL0001094'; // 유해인자 상세
        this.popupOptions.param = { 
          hazardCd: row.checkItemId,
        };
        this.popupOptions.target = () => import(`${"@/pages/sai/wmr/harmfulFactorsDetail.vue"}`);
      }
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      this.research();
    },
    openImprRequest() {
      let requestContents = `${this.scenario.ramCheckItemClassName} / ${this.scenario.checkItemName}`
      
      this.popupOptions.title = 'LBLIMPRREQUEST';
      this.popupOptions.param = {
        requestContents: requestContents,
        relationTableKey: this.scenario.ramChecklistAssessScenarioId,
        ibmTaskTypeCd: 'ITT0000010',
        ibmTaskUnderTypeCd: 'ITTU000010',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCardImprPopup;
    },
    closeCardImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      this.getImprs();
    },
    linkClick(data) {
      this.popupOptions.title = 'LBLIMPROVE';
      this.popupOptions.param = {
        sopImprovementId: data.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCardImprPopup;
    },
    /* eslint-disable no-unused-vars */
    listView() {
      this.scenarioViewGroup = 'L'
    },
    cardView() {
      this.scenarioViewGroup = 'C'
    },
    saveScenario() {
      if (this.$comm.validTable(this.grid.columns, this.target.scenarios)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveScenarioCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.research();
      this.getList();
    },
    saveScenarioUnit() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.scenario.chgUserId = this.$store.getters.user.userId

              this.isSaveUnit = !this.isSaveUnit
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveScenarioUnitCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.research();
      this.getList();
    },
    removeScenario() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.target.scenarios.splice(this.$_.findIndex(this.target.scenarios, item), 1)
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();

              this.planUpdateBtnData.planResearch = uid();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    openImprPicture(props) {
      this.popupOptions.title = 'LBL0001181'; // 개선 사진
      this.popupOptions.param = {
        ramChecklistAssessScenarioId: props.row.ramChecklistAssessScenarioId,
        sopImprovementIds: props.row.sopImprovementIds,
        disabled: this.disabled
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/checklist/action/checklistScenarioImprPicture.vue'}`);
      this.popupOptions.width = '30%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeScenarioImprPicturePopup;
    },
    closeScenarioImprPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    compareScenario() {
      let uri = `/ram/compare/compareScenario?ramTechniqueCd=${this.param.ramTechniqueCd}&ramRiskAssessmentPlanId=${this.param.ramRiskAssessmentPlanId}&processCd=${this.target.processCd}&jobName=${this.target.checkItemName}&ramCheckItemClassCd=${this.target.ramCheckItemClassCd}&checkItemName=${this.target.checkItemName}`;
      this.$refs.winPopup.openWinPop(uri, 1560, 700);
    },
    evtCloseWinPopup() {
      Object.assign(this.$data.activeWinProps, this.$options.data().activeWinProps);
    },
    onRecvWinPop(recvObj) {
      if(recvObj) {
        this.$set(this.activeWinProps, 'processCd', recvObj.processCd)
        this.$set(this.activeWinProps, 'ramChecklistAssessScenarioId', recvObj.ramChecklistAssessScenarioId)
      }
    },
    setTrClass(props) {
      return props.row.processCd === this.activeWinProps.processCd 
        && props.row.compareId === this.activeWinProps.ramChecklistAssessScenarioId ? 'bg-purple-2' : '';
    },
  }
};
</script>
